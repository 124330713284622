import React, { useEffect } from "react";
import CountDownTimer from "components/Countdown/CountdownTimer";

const IntroHomeLR = ({ sliderRef, blackStar }) => {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#particles-js canvas')?.style.removeProperty('position');
    }, 500);
  }, []);

  return (
    <header ref={sliderRef} className="particles circle-bg valign bg-hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cont text-center">
              <h1 className="pb-4 pt-4">
                <span className="color-font">Little River Music Festival</span>
              </h1>
              {/* <h2>Celebrating Community & Music</h2> */}
              <a href="https://maps.app.goo.gl/K1wSBY8i3MUmnzj68" target="_blank" rel="noreferrer">
                <h4 className="pb-4">
                  <i class="fas fa-map-marker-alt"></i> 130 Western Valley Road, Little River
                </h4>
              </a>
              <h2 className="pb-4"><span className="color-font">3rd February 2024</span> | <span className="color-font">11 AM - 8 PM</span></h2>
              <p className="pb-4">
              After a 10 year hiatus the Little River Music Festival returns to the hills and valleys to celebrate exceedingly excellent local musicians.  Little River Music Festival is looking to bring a relaxed and enjoyable day out for the entire family. Bring your camp chair, picnic blanket and be ready to enjoy a great day out with music, various food vendors and  bars.              </p>
              <div className="">
                <CountDownTimer date={new Date(2024, 1, 3, 11, 0)}/>
              </div>
              <div className="butons mt-40 pb-4">
                <a href="https://www.cosmicticketing.co.nz/event/6883" target="_blanck" className="butn-gr rounded buton">
                  <span>Buy Ticket Now</span>
                </a>
              </div>
              <div><h6>Gain a hefty discount when purchasing bundles of 4 tickets by using the PROMO CODE <span className="color-font">4FOR300</span></h6></div>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient-circle"></div>
      <div className="gradient-circle two"></div>
      <div className="line bottom left"></div>
    </header>
  );
};

export default IntroHomeLR;
