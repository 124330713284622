import React from "react";
import { Link } from 'gatsby';
// import worksData from "data/sections/works.json";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const ArtistsHomePage = ({artists}) => {
  const swiperRef = React.useRef(null);
  return (
    <>
      <section className="work-carousel2 metro section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head">
                <h3 className="wow mb-20 color-font animated" data-wow-delay=".5s">Artists
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 no-padding">
              <div className="swiper-container">
                <Swiper
                  ref={swiperRef}
                  className="swiper-wrapper"
                  slidesPersView={3}
                  centeredSlides={true}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  onAfterInit={(swiper) => {
                    setTimeout(() => {
                      swiper.slideNext(2500);
                    }, 2500)
                  }}
                  onSlideChangeTransitionEnd={(swiper) => {
                    setTimeout(() => {
                      swiper.slideNext(2500);
                    }, 2500)
                  }}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  speed={2000}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    767: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                      centeredSlides: false,
                    },
                    991: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {artists.map((artist, ind) => {
                    // console.log(artist, ind);
                    return <SwiperSlide className="swiper-slide" key={`artist_${artist.id}`}>
                    <div className="content">
                      <div className="img" style={{
                        height: '40vh',
                        width: '40vh',
                        borderRadius: '50%',
                      }}>
                        <span className="imgio h-100 w-100" style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        
                        }}>
                          <span className="wow cimgio" data-delay="1000"></span>
                          <img src={artist.image} alt="" className="center-block" style={{
                            minWidth: '100%',
                            minHeight: '100%',
                            // width: '100%',
                            // height: 'auto',
                          }}/>
                        </span>
                      </div>
                      <div className="cont">
                        <h4>
                          <Link
                            to={artist.link.trim() !== '' ? artist.link : '/'}
                            target="blank"
                          >
                            {artist.title.first} {artist.title.second}
                          </Link>
                        </h4>
                        <h6>
                          <Link
                            to={artist.link.trim() !== '' ? artist.link : '/'}
                            target="blank"
                          >
                            {artist.description}
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </SwiperSlide>

                  })}
                  <div
                     onClick={() => swiperRef.current.swiper.slideNext(1000)} 
                    className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
                  >
                    <i className="ion-ios-arrow-right"></i>
                  </div>
                  <div
                     onClick={() => swiperRef.current.swiper.slidePrev(1000)} 
                    className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
                  >
                    <i className="ion-ios-arrow-left"></i>
                  </div>
                </Swiper>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ArtistsHomePage;
