import React, { useEffect, useRef, useState } from 'react';

import CountDownCard from 'components/Countdown/CountdownCard';

const CountDownTimer = ({date}) => {
  //card ref
  const SecondsCardRef = useRef(null);
  const MinutesCardRef = useRef(null);
  const HoursCardRef = useRef(null);
  const DaysCardRef = useRef(null);
  const diff = date - Date.now();
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const diffDay = diff / ONE_DAY
  const diffHour = (diffDay - Math.floor(diffDay)) * 24
  const diffMinutes = (diffHour - Math.floor(diffHour)) * 60
  const diffSecondes = (diffMinutes - Math.floor(diffMinutes)) * 60
  //state
  const [days, setDays] = useState(Math.floor(diffDay));
  const [hours, setHours] = useState(Math.floor(diffHour));
  const [minutes, setMinutes] = useState(Math.floor(diffMinutes));
  const [seconds, setSeconds] = useState(Math.floor(diffSecondes));

  useEffect(() => {
    seconds === 0 && setSeconds(59);
    minutes === 0 && setMinutes(59);
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
        SecondsCardRef.current?.classList.toggle('rotate');
      }, 1000);
    }
    if (seconds === 0 && minutes > 0) {
      setMinutes(minutes - 1);
      MinutesCardRef.current?.classList.toggle('rotate');
    }
  }, [seconds, minutes]);
  useEffect(() => {
    hours === 0 && setHours(23);
    if (minutes === 0 && hours > 0) {
      setHours(hours - 1);
      HoursCardRef.current?.classList.toggle('rotate');
    }
  }, [minutes, hours]);
  useEffect(() => {
    days === 14 && setDays(13);
    hours === 0 &&
      setDays(days - 1) &&
      DaysCardRef.currents?.classList.toggle('rotate');
  }, [hours, days]);
  return (
    <div className="countdown__container w-50">
      <CountDownCard
        label="days"
        number={days}
        cardRef={DaysCardRef}
      />
      <CountDownCard
        label="hours"
        number={hours}
        cardRef={HoursCardRef}
      />
      <CountDownCard
        label="minutes"
        number={minutes}
        cardRef={MinutesCardRef}
      />
      <CountDownCard
        label="seconds"
        number={seconds}
        cardRef={SecondsCardRef}
      />
    </div>
  );
};

export default CountDownTimer;