"use client"
import React, { useCallback } from "react";
import DarkTheme from "layouts/Dark";
import initIsotope from "common/initIsotope";
// import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import { graphql } from 'gatsby';
import NavbarLR from "components/NavbarLR/navbar";
import IntroHomeLR from "components/IntroHomeLR/IntroHomeLR";
import particlesConfig from "config/particle-config";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import Footer from "components/Footer/footer";
import artists from "data/artists.json";
import Sponsors from "components/Sponsors/sponsors";
import ArtistsHomePage from "components/ArtistsHomePage/ArtistsHomePage";
import BlogContent from "components/Blog-Content/blog-content";

const IndexPage = ({ data }) => {
  // let story = data.storyblokEntry
  // story = useStoryblokState(story)

  // const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const [pageLoaded, setPageLoaded] = React.useState(false);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document.querySelector('#particles-js canvas')?.style.removeProperty('position');
  }, []);

  React.useEffect(() => {
    setPageLoaded(true);
    if (pageLoaded) {
      window.addEventListener("load", () => {
        setTimeout(() => {
          if (pageLoaded) initIsotope();
          document.querySelector('#particles-js canvas')?.style.removeProperty('position');
        }, 500);
      });
    }

    setTimeout(() => {
      initIsotope();
      document.querySelector('#particles-js canvas')?.style.removeProperty('position');
    }, 500);

  }, [pageLoaded]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 150) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme mobileappstyle={true}>
      <Particles
        id="particles-js"
        init={particlesInit}
        options={particlesConfig}
      />

      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <IntroHomeLR />
      <BlogContent>
        <h3 className="wow color-font extra-title">Tips for your day out</h3>
        <p class="text-white">Hey festival goers 😃 A few handy tips for your day out:</p>
        <p class="text-white">

          <ul>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>01</span>Please have FUN!!</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>02</span>Follow the signs along Western Valley Rd to the car parking area, where there will be parking wardens to direct you. This is festival parking, but not overnight.  PM us if you are stuck for camper parking and we can send you info.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>03</span>Please no Dogs - we would love to accommodate your furry friends but due to it being a working farm we are unable to.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>04</span>BYO Alcohol is NOT permitted but we do have a Bar onsite.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>05</span>BYO Food IS permitted and there will be a number of food vendors making delicious food.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>06</span>Gates open at 10am - Music starts at 11am.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>07</span>Gate sales available on the day - Cash or Eftpos.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>08</span>As responsible hosts we will have safe transport options to deliver people home within the close surrounds - but due to the limited nature of this service we do recommend you elect a sober driver to get your crew home safe n sound.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>09</span>Sensible footwear is recommended due to it being farmland - it may be a good idea to ditch the high heels for this one.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>10</span>Vendors accept Cash and Eftpos.</li>
            <li><span className="wow color-font text-large" style={{fontSize:'150%'}}>11</span>And did we mention.....Please have FUN!!</li>
          </ul>
        </p>
      </BlogContent>
      <ArtistsHomePage artists={artists}/>
      <Sponsors theme={"dark"} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Little River Music Festival</title>
      {/* <link rel="stylesheet" href="demo.css" /> */}
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`